.App {
    width: 100%;
    /* height: 100vh;
    display: flex; */
    justify-content: center;
    align-items: center;
}

.App table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
}

.App th {
    border-bottom: 1px solid black;
    text-align: center;
}

.App td {
    text-align: center;
}

.App table,
.App th,
.App td {
    border: 1px solid;
    border: 2px solid forestgreen;
    width: 800px;
    height: 51px;
}
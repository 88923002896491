#pdf-editor-uploader{
    display:none
}
.upload-button{
    color: #354f6e;
    border : 2px solid #354f6e; 
    border-radius: 5px;
    padding: 0.5rem;  
    cursor: pointer;
    font-weight: bolder;
    background-color: white;
    line-height: unset;
    &:hover{
        background-color: #354f6e;
        color:white;
    }
}
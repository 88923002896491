.claimPdf{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;
    background: white;
}
.topbar{
    height: 60px;
    background-color: whitesmoke;
    width: 100%;
    border-bottom: 1px solid lightgray;
    display: flex;  
    align-items: center;  
}
.topbar img{
    height: 60%;
    object-fit: contain;
    margin-left: 0.5rem;
}
.claimInfo{
    margin: 0.5rem 0rem;
    display: flex;
    justify-content: space-between;   
    flex-wrap: wrap;
}
.claimNav{  
    /* margin: 0.5rem 5rem; */
    // width: 100%;
    display: flex;
    justify-content: space-between;
}
.claim-button-start{  
    padding: 0.1rem 2rem 0.1rem 0.5rem;  
    cursor: pointer; 
    // display: flex;
    align-items: center;
    justify-content: center; 
    text-align: center;   
    width: 30%; 
    background-color: #d3d3d361;
    clip-path: polygon(0% 0%, 88% 0%, 100% 50%, 88% 100%, 0% 100%);
    /* background: url("../assets/ChevronStart.png") ; */
    /* background-size:cover; 
    background-repeat: no-repeat; */
}
.claim-button{
    padding: 0.1rem 2rem 0.1rem 2rem;  
    width: 30%; 
    background-color: #d3d3d361; 
    // display: flex;
    // flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center; 
    text-align: center;  
    clip-path: polygon(88% 0%, 100% 50%, 88% 100%, 0% 100%, 10% 50%, 0% 0%);   
    z-index: 9;   
}
.claim-button:hover,.claim-button-start:hover{
    scale: 1.05;
}

/* .claim-button-border{
    position: absolute;
    background-color: black;
    clip-path: polygon(86% 0%, 100% 50%, 86% 100%, 0% 100%, 0% 0%, 0% 0%);
    clip-path: polygon(0% 0%, 76% 0%, 100% 50%, 75% 100%, 0% 100%);;
} */
.pdf-viewer{
     margin: 0.5rem 0;
}
.pdf-container{
    width: 100%;
    height: calc(100vh - 170px);
}

.claim-button-sub-wrap{
    display: flex;
    width: 100%;
    justify-content: end;
    .page-numbers{
        display: flex;
        min-width: 33.33%;
        max-width:max-content;
        justify-content: center;
        
    }
    .trash-icon{
        display: flex;
        width: 33.33%;
        justify-content: end;
        align-items: center;       
        @media screen and (min-width: 2048px) {
            padding-right: 6%;
        }
        @media screen and (min-width: 2600px) {
            padding-right: 8%;
        }
        @media screen and (min-width: 3000px) {
            padding-right: 10%;
        }
        @media screen and (min-width: 4000px) {
            padding-right: 12%;
        }
    }
    a{
        line-height: unset;
        padding: 0 0.1rem;
    }
}

.document-name{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    background: lightgray;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
    .name{
        margin-left: 0.3rem;
        flex-grow: 1;
        text-align: left;
    }
}
.left-overlay {
    max-width: 17vw;
    background-color: lightgray;
    position: fixed;
    margin-top: 10%;
    margin-left: 0.25%;
    border-radius: 7px;
    z-index: 99;
    max-height: 60vh;
    overflow-y: auto;
}
.top-overlay {   
    background-color: lightgray;
    position: fixed;
    margin-top: 5.5rem;
    margin-left: 0.25%vw;
    border-radius: 7px;
    z-index: 99;   
    max-width: 50vw;
    max-height: 30vh; 
    overflow-y: auto;   
}

.guidelines {
    padding: 0.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.guidelines-wrap{
    display: flex;
    .menu{
        writing-mode: vertical-rl;
        // text-orientation: upright;
        text-transform: uppercase;                
        background: rgb(180, 180, 180);        
        a{
            line-height: unset;            
            padding: 0.1rem;
            font-weight: bold;
            cursor: pointer;
            color: black !important;
           
        }
    }
}
#chainOfTitleGuidelines{    
    font-size: small;
    a{
        line-height: unset;   
        padding: 0.2rem;
        font-weight: bold;
        cursor: pointer;
        color: black !important;
       
    }

}
.text-underline{
    text-decoration: underline;
}
.table-bordered{
    td,th{
        border: 1px solid #dee2e6 !important;
       
    }
}
.claim-file-color{
    color: blue;
}

.chain-of-title {
    table {
        height: 1px;
    }

    tr {
        height: 100%;
    }

    td {
        height: 100%;
    }

    td>div {
        height: 100%;
    }

}
.document-upload {
    .p-panel .p-panel-content {
        border-color: #DEE2E6 !important;
        border-radius: 0 0 6px 6px;
    }

    .p-panel .p-panel-titlebar {
        background-color: #F8F9FA !important;
        border-color: #DEE2E6 !important;
        border-radius: 6px 6px 0 0;
    }

    .p-dropdown-panel .p-dropdown-filter-container {
        width: 100%;
    }

    .p-dropdown {
        width: 100%;
    }

    .p-autocomplete {
        width: 100% !important;
    }

    .upload-header{
        background-color: #F8F9FA;
        border: 1px solid #DEE2E6;
        border-radius: 6px 6px 0 0;
        padding: 0.3rem 0.5rem;
        font-size: 90%;
    }
    .years-header{
        border-radius:6px;
    }

    .upload-content {
        padding: 0.5rem;
        border: 1px solid #DEE2E6;
        border-radius: 0 0 6px 6px;

    }

    #doc-uploader {
        display: none
    }

    .doc-upload-button {
        color: white;
        border: 2px solid #354f6e;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        font-weight: bolder;
        background-color: #354f6e;
        line-height: unset;
        margin-bottom: 0;        
        &:hover {
            background-color: #354f6e;
            color: white;
        }

       
    }
        .doc-upload-button-disable,.doc-upload-button-disable:hover,
        .doc-upload-button[disabled] {
            background-color: lightgray;
            border-color: lightgray;
            color: black;
            cursor: default;
            pointer-events: none;
        }

    .table {
        border-radius: 6px !important;
    }

    .table tbody td,
    .table th {
        border: 1px solid #DEE2E6 !important;
    }

    .table thead th,
    .table tbody tr .header {
        background-color: #F8F9FA !important;
    }

    #document-upload-modal {
        .modal-content {
            padding: 0 !important;
        }
        .side-bar-body{
            label{
                margin-bottom: 0px !important;
            }
        }
    }

    .modal.fade .modal-header {
        background-color: #F8F9FA !important;
        border: 1px solid #DEE2E6 !important;
        color: black;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

   

    .p-autocomplete-input {
        border-right: solid 1px lightgray !important;
        width: 100% !important;
    }

    .p-progressbar .p-progressbar-value {
        background: #4cc0ea !important;
    }

    .p-progressbar {
        border-radius: 6px !important;
    }

    .p-progressbar-label {
        color: #333333 !important;
    }
    .form input{
        height: unset;
    }
    .p-panel .p-panel-titlebar {
        padding: 0.25em 1em;
    }
    .table tbody td, .table th {
        padding: 5px;
    }
    .p-input-overlay-visible {
        min-width: unset !important;
    }
    .p-dropdown-items-wrapper{
        max-height: 400px !important;
    }
    .sellerCNNamesDropDown {
        .p-input-overlay-visible {
            min-width: max-content !important;
        }
    }
    
}
.side-bar-body{
    label{
        margin-bottom: 0px !important;
    }
}
.upload-header{
    background-color: #F8F9FA;
    border: 1px solid #DEE2E6;
    border-radius: 6px 6px 0 0;
    padding: 0.3rem 0.5rem;
    font-size: 90%;
}
.years-header{
    border-radius:6px;
}

.upload-content {
    padding: 0.5rem;
    border: 1px solid #DEE2E6;
    border-radius: 0 0 6px 6px;

}

#doc-uploader {
    display: none
}

.doc-upload-button {
    color: white;
    border: 2px solid #354f6e;
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    font-weight: bolder;
    background-color: #354f6e;
    line-height: unset;
    margin-bottom: 0;        
    &:hover {
        background-color: #354f6e;
        color: white;
    }

   
}
    .doc-upload-button-disable,.doc-upload-button-disable:hover,
    .doc-upload-button[disabled] {
        background-color: lightgray;
        border-color: lightgray;
        color: black;
        cursor: default;
        pointer-events: none;
    }

.doc_upload_table {
    td,th{
        font-size: smaller;
    }
}
.config-error-message{
    display: flex;
    justify-content: center;
    font-size: large;
    padding: 1rem;
    background: #faebd773;
    border-radius: 0.3rem;
}
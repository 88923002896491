.loadNone {
    display: none !important;
}
.loadPage{
    background:none !important; 
}
.loadPageProp {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
     background:none !important;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}